<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col md="8" sm="10" cols="10">
          <v-text-field
            placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="4" cols="4">
          <v-btn block color="amber darken-4" dark @click="dialogSave()">
            <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
          </v-btn>
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table :headers="header" :items="objects" :search="search" dense locale="pt-BR">
        <template v-slot:[`item.action`]="{ item }">
          <TheToolTip label="Editar">
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Remover">
            <v-btn icon small tile>
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>
    </v-card>

    <TheDialog v-model="dialog" :title="title" :icon="pageIcon">
      <template>
        <v-form v-model="valid" ref="form1">
          <b> Nome <sup>*</sup></b>
          <v-text-field
            placeholder="Nome"
            v-model="object.name"
            class="mt-2 mb-5"
            dense
            hide-details
            outlined
            color="primary"
            :rules="requiredRule"
          ></v-text-field>
          <b> Descrição <sup>*</sup></b>
          <VueEditor v-model="object.content" :editor-toolbar="customToolbar" class="mt-2" />
        </v-form>
      </template>
      <template v-slot:actions="">
        <v-btn small depressed color="primary" class="mt-2" dark @click="resolveForm()">
          <v-icon small class="mr-2">mdi-plus</v-icon>
          <span v-if="!edit">Adicionar</span>
          <span v-if="edit">Atualizar </span>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
      </template>
    </TheDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import TheTitle from '@/components/TheTitle'
import TheDialog from '@/components/TheDialog'
import TheToolTip from '@/components/TheToolTip'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'Dados',
  components: {
    TheTitle,
    TheDialog,
    TheToolTip,
    VueEditor,
  },
  data: () => ({
    baseRoute: 'text',
    title: 'Textos',
    pageIcon: 'mdi-file-document-outline',
    dialog: false,
    object: {},
    valid: false,
    // requiredRule: [(v) => !!v || 'O Campo é necessário'],
    header: [
      { text: 'Nome', value: 'name' },
      { text: 'Ações', value: 'action', width: '10%' },
    ],
    objects: [],
    error: false,
    edit: false,
    customToolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
    search: null,
  }),
  methods: {
    loadData() {
      const url = `${baseApiUrl}/${this.baseRoute}`
      this.$store.dispatch('setLoadingInfo', true)
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch('setLoadingInfo', false)
          this.objects = res.data
        })
        .catch(showError)
    },
    dialogSave() {
      this.object = {}
      this.edit = false
      this.dialog = true
    },
    dialogUpdate(item) {
      this.object = item
      this.edit = true
      this.dialog = true
    },
    resolveForm() {
      this.$refs.form1.validate()
      if (!this.valid || !this.object.content) {
        this.$store.dispatch('setErrorInfo', {
          open: true,
          text: 'Os campos não foram preenchidos corretamente, você pode verificar?',
          button: 'Ok!',
        })
        return
      }
      if (this.edit) {
        this.update()
      } else {
        this.save()
      }
    },
    save() {
      this.error = false
      const url = `${baseApiUrl}/${this.baseRoute}`
      const objects = this.object
      axios
        .post(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    update() {
      this.error = false
      const id = this.object.id
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object

      delete objects.id
      delete objects.expanded
      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt

      axios
        .put(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    remove(id) {
      if (confirm('Remove?')) {
        const url = `${baseApiUrl}/${this.baseRoute}/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style>
  .full-height {
    height: 80vh;
  }
</style>
